<template>
  <layout-base>
    <app-header v-if="menu" />

    <v-layout class="overflow-inherit!">
      <v-main>
        <slot />
      </v-main>
    </v-layout>

    <app-footer v-if="menu" />
    <credit-footer v-else />

    <div v-if="menu && !noMobileNav" class="md:hidden">
      <mobile-nav />
    </div>
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { get } from "lodash";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";
import AppHeader from "~~/components/layout/headers/SimpleHeader.vue";
import AppFooter from "~~/components/layout/footers/SimpleFooter.vue";
import CreditFooter from "~~/components/layout/footers/CreditFooter.vue";
import MobileNav from "~~/components/layout/footers/MobileNav.vue";

export default defineComponent({
  components: {
    LayoutBase,
    AppHeader,
    AppFooter,
    CreditFooter,
    MobileNav,
  },
  computed: {
    menu() {
      return this.$route.query.menu !== "false";
    },
    noMobileNav() {
      return get(this.$route.meta, "noMobileNav", false);
    },
  },
});
</script>
