<template>
  <v-footer class="footer">
    <v-divider />
    <div class="sublinks mt-4 mb-10">
      <v-row align-content="center" class="ma-0 px-0">
        <v-col cols="12" md="auto" class="pa-0">
          <v-row class="h-full" align-content="center" no-gutters>
            <span>
              © {{ new Date().getFullYear() }} Orie. All rights reserved.
            </span>
          </v-row>
        </v-col>

        <v-col cols="12" md="auto" class="pa-0 ml-md-10">
          <v-row class="h-full" align-content="center" no-gutters>
            <template v-for="(link, i) in legalLinks" :key="link.text">
              <span class="mx-2" v-if="i">·</span>
              <router-link class="dotted-link" :to="link.to">
                {{ link.text }}
              </router-link>
            </template>
          </v-row>
        </v-col>

        <v-spacer class="md:hidden" />

        <v-col cols="12" md="auto" class="md:hidden">
          <span class="mr-5">
            Handcrafted with
            <span class="text-red">
              <v-icon size="15" icon="favorite" />
            </span>
          </span>
          <theme-switch class="mr-3" size="x-small" variant="text" />
          <span class="font-weight-bold">
            {{ currency.symbol }} {{ currency.code }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeSwitch from "../../elements/ThemeSwitch.vue";

export default defineComponent({
  components: { ThemeSwitch },
  setup() {
    const { currency } = useCurrency();
    const config = useRuntimeConfig();

    return {
      currency,
      brochureUrl: config.public.links.brochure,
      dashboardUrl: config.public.links.dashboard,
    };
  },
  data: () => ({
    legalLinks: [
      {
        text: "Terms of Use",
        to: "/legal/terms",
      },
      {
        text: "Privacy Policy",
        to: "/legal/privacy",
      },
      {
        text: "Sales",
        to: "/legal/sales",
      },
      // {
      //   text: "Sales and Refunds",
      //   to: "/legal/sales",
      // },
      {
        text: "Legal",
        to: "/legal",
      },
      // { text: "Blog", to: { name: "blog" } },
    ],
  }),
  computed: {
    companyLinks() {
      return [
        {
          text: "For business",
          to: `//${this.brochureUrl}`,
        },
        {
          text: "About us",
          to: { name: "about" },
        },
        {
          text: "Policies",
          to: "/legal",
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.footer {
  display: block;
  width: 100vw;
  .sublinks {
    width: 100%;
    font-size: 0.7rem;
    @include breakpoints-up(md) {
      font-size: 0.8rem;
    }
  }
  @include breakpoints-down(md) {
    margin-bottom: 50px;
  }
}
</style>
